<template>
  <div class="main">
    <div class="content">
      <div class="logo mobile-show">
        <img src="@/assets/image/loginLogo.png" alt />
      </div>
      <svg @click="handleClose" t="1705470289990" class="icon close" viewBox="0 0 1024 1024" version="1.1" p-id="3432">
        <path
          d="M898.844444 830.577778c22.755556 17.066667 22.755556 51.2 0 73.955555s-51.2 22.755556-73.955555 0L512 591.644444l-312.888889 312.888889c-11.377778 11.377778-22.755556 17.066667-39.822222 17.066667-11.377778 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-22.755556-51.2 0-73.955555l312.888889-312.888889-307.2-318.577778c-11.377778-5.688889-17.066667-22.755556-17.066667-34.133333 0-11.377778 5.688889-28.444444 17.066667-34.133334 22.755556-22.755556 51.2-22.755556 73.955555 0L512 443.733333l312.888889-312.888889c22.755556-22.755556 51.2-22.755556 73.955555 0s22.755556 51.2 0 73.955556l-312.888888 312.888889 312.888888 312.888889z"
          fill="#727477"
          p-id="3433"
        />
      </svg>
      <!-- 登录 -->
      <div class="main_center" v-if="status == 'dl'">
        <div class="main_top">登录</div>
        <div class="welcome pc-show">欢迎光临网站!</div>
        <div class="main_input">
          <input type="text" v-model="form.phoneNumber" placeholder="请输入账号" />
        </div>
        <div class="main_input">
          <input type="password" placeholder="请输入密码" v-model="form.password" @keyup.enter="handleLogin" />
        </div>
        <div class="main_pwd">
          <span class="main_input_right" @click="handleGo('wjmm')">忘记密码？</span>
        </div>
        <div class="main_center_bottom">
          <div :class="['confirm', { confirmed: select }]" @click="handleAgree"></div>
          <div class="cont">我已满18岁，并阅读和同意《用户协议》、《隐私政策》和《反洗钱协议》，承诺理性消费。</div>
        </div>
        <div class="main_bottom" @click="handleLogin">
          <button>立即登录</button>
        </div>
        <div class="main_bottom_no">
          没有账号?
          <span @click="handleGo('zc')">去注册</span>
        </div>
      </div>
      <div class="main_center" v-if="status == 'wjmm'">
        <div class="main_top">忘记密码</div>
        <div class="main_input">
          <input type="text" v-model="forgetform.phoneNumber" placeholder="请输入手机号" />
        </div>
        <div class="main_input">
          <input type="text" placeholder="请输入验证码" v-model="forgetform.code" />
          <div @click="getyzm" class="yzm">{{ title }}</div>
        </div>
        <div class="main_input">
          <input type="password" placeholder="请输入新的密码" v-model="forgetform.password" />
        </div>
        <div class="main_input">
          <input type="password" placeholder="请输入确认密码" v-model="forgetform.confirmPassword" />
        </div>
        <div class="main_bottom" @click="handleForget"><button>确认</button></div>
        <div class="main_bottom_no">
          已有账号?
          <span @click="handleGo('dl')">去登录</span>
        </div>
      </div>
      <div class="main_center" v-if="status == 'zc'">
        <div class="main_top">注册</div>
        <div class="main_input">
          <input type="text" v-model="registerform.phoneNumber" placeholder="请输入手机号" />
        </div>
        <div class="main_input">
          <input type="text" v-model="registerform.nickName" placeholder="请输入昵称" />
        </div>

        <div class="main_input">
          <input type="password" placeholder="请输入新的密码" v-model="registerform.password" />
        </div>
        <div class="main_input">
          <input type="password" placeholder="请输入确认密码" v-model="registerform.confirmPassword" />
        </div>
        <div class="main_input">
          <input type="text" placeholder="请输入验证码" v-model="registerform.code" />
          <div @click="getyzm" class="yzm">
            {{ title }}
          </div>
        </div>
        <div class="main_input">
          <input type="text" placeholder="请输入邀请码（默认为500）" v-model="registerform.parentInvitationCode" />
        </div>
        <div class="main_center_bottom">
          <div :class="['confirm', { confirmed: select }]" @click="handleAgree"></div>
          <div class="cont">我已满18岁，并阅读和同意《用户协议》、《隐私政策》和《反洗钱协议》，承诺理性消费。</div>
        </div>
        <div class="main_bottom" @click="handleRegister"><button>注册</button></div>
        <div class="main_bottom_no">
          已有账号?
          <span @click="handleGo('dl')">去登录</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLogin, getRegister, getcode, changePass } from '../../../api/index';
import { browser } from '@/utils/utils';
import { setToken } from '@/api/cookie';
import { getUserInfo } from '@/api/index';
import Socket from '@/utils/socket';
let formPath = '';

export default {
  data() {
    return {
      disabled: false,
      timer: null,
      second: '',
      invitationCode: this.$route.query.code,
      registerform: {
        code: '',
        nickName: '',
        parentInvitationCode: '',
        password: '',
        phoneNumber: '',
        confirmPassword: '',
      },
      forgetform: {
        code: '',
        nickName: '',
        parentInvitationCode: '',
        password: '',
        phoneNumber: '',
        confirmPassword: '',
      },
      status: 'dl',
      select: false,
      form: {
        code: '',
        nickName: '',
        parentInvitationCode: '',
        password: '',
        phoneNumber: '',
      },
    };
  },
  mounted() {
    if (this.invitationCode) {
      console.log(11);

      this.status = 'zc';
      this.registerform.parentInvitationCode = this.invitationCode;
    }
  },
  beforeRouteEnter(to, from, next) {
    formPath = from.fullPath;
    next();
  },
  computed: {
    title() {
      return this.disabled ? `${this.second}s` : '获取验证码';
    },
  },
  methods: {
    playAudio() {
      this.$store.commit('playAudio');
    },
    handleForget() {
      this.playAudio();
      if (this.forgetform.phoneNumber == '') {
        this.$message({
          offset: 50,
          message: '请输入新密码或手机号',
          type: 'warning',
        });
        return;
      } else if (this.forgetform.password == '') {
        this.$message({
          offset: 50,
          message: '请输入密码！',
          type: 'warning',
        });
      } else if (this.forgetform.password != this.forgetform.confirmPassword) {
        this.$message({
          offset: 50,
          message: '密码输入不一致，请重新输入！',
          type: 'warning',
        });
      } else {
        let data = {
          code: this.forgetform.code,
          confirmPassword: this.forgetform.confirmPassword,
          password: this.forgetform.password,
          phoneNumber: this.forgetform.phoneNumber,
        };
        changePass(data).then(res => {
          if (res?.data?.code == 200) {
            this.$message({
              offset: 50,
              message: '密码重置成功',
              type: 'success',
            });
            this.status == 'dl';
          } else {
            this.$message({
              offset: 50,
              message: '验证码错误，请重试',
              type: 'warning',
            });
          }
        });
      }
    },
    getyzm() {
      this.playAudio();
      if (this.disabled) return;
      let dataform = {};
      if (this.status == 'zc') {
        if (this.registerform.phoneNumber == '') {
          this.$message({
            offset: 50,
            message: '请输入用户名或手机号',
            type: 'warning',
          });
          return;
        }
        dataform.type = 1;
        dataform.phoneNumber = this.registerform.phoneNumber;
      } else if (this.status == 'wjmm') {
        if (this.forgetform.phoneNumber == '') {
          this.$message({
            offset: 50,
            message: '请输入用户名或手机号',
            type: 'warning',
          });
          return;
        }
        dataform.phoneNumber = this.forgetform.phoneNumber;
        dataform.type = 4;
      }
      getcode(dataform).then(res => {
        if (res?.data?.code == 200) {
          let s = 60; //倒计时间
          this.second = s;
          this.disabled = true;
          this.timer = setInterval(() => {
            if (this.second > 0 && this.second <= s) {
              this.second--;
            } else {
              this.disabled = false;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
          this.$message({
            offset: 50,
            message: `${res.data.msg}`,
            type: 'success',
          });
        }
        if (res?.data?.code == 500) {
          this.$message({
            offset: 50,
            message: `${res.data.msg}`,
            type: 'warning',
          });
        }
      });
    },
    handleGo(res) {
      this.playAudio();
      this.status = res;
    },
    handleRegister() {
      this.playAudio();
      if (this.select == false) {
        this.$message({
          offset: 50,
          message: '请点击同意用户协议',
          type: 'warning',
        });
      } else {
        if (this.registerform.phoneNumber == '' && this.registerform.nickName == '') {
          this.$message({
            offset: 50,
            message: '请输入用户名或手机号',
            type: 'warning',
          });
          return;
        } else if (this.registerform.password == '') {
          this.$message({
            offset: 50,
            message: '请输入密码',
            type: 'warning',
          });
        } else if (this.registerform.confirmPassword != this.registerform.password) {
          this.$message({
            offset: 50,
            message: '两次密码不一致',
            type: 'warning',
          });
        } else if (this.registerform.code == '') {
          this.$message({
            offset: 50,
            message: '请获取验证码',
            type: 'warning',
          });
        } else {
          if (this.registerform.parentInvitationCode == '500') {
            this.registerform.parentInvitationCode = '';
          }
          getRegister(this.registerform).then(res => {
            if (res?.data?.code == 200) {
              this.$message({
                offset: 50,
                message: '注册成功，登录中…',
                type: 'success',
              });
              this.status = 'dl';
              let data = {
                username: this.registerform.phoneNumber,
                password: this.registerform.password,
                equipment: '1',
              };
              const curBrowser = browser();
              if (!curBrowser.mobile) {
                // data.equipment='1'
              } else if (curBrowser.webapp) {
                data.equipment = '2';
              } else {
                data.equipment = '1';
              }
              getLogin(data)
                .then(res => {
                  let code = res?.data?.code;
                  if (code == 200) {
                    setToken(res.data.token);
                    this.$store.commit('LOGIN_IS_SHOW', false);
                    this.$message({
                      offset: 50,
                      message: '登录成功',
                      type: 'success',
                    });
                    //获取用户信息
                    getUserInfo().then(res => {
                      const { code, data } = res?.data || {};
                      if (code != 200) return;
                      this.connectWs(data.userId);
                      this.$store.commit('USER_INFO', data);
                      this.$router.replace(formPath || '/');
                      this.$router.go(0);
                    });
                  } else {
                    if (res.data.msg == '用户已封禁，请联系管理员') {
                      this.$message({
                        offset: 50,
                        message: '账户异常，请联系管理员',
                        type: 'warning',
                      });
                    } else {
                      this.$message({
                        offset: 50,
                        message: res.data.msg,
                        type: 'warning',
                      });
                    }
                  }
                })
                .catch(err => {
                  throw new Error(err.message);
                });
              // this.index = 2;
              this.registerform = {
                code: '',
                nickName: '',
                parentInvitationCode: '',
                password: '',
                phoneNumber: '',
                confirmPassword: '',
              };
            } else {
              this.$message({
                offset: 50,
                message: res.data.msg,
                type: 'warning',
              });
            }
          });
        }
      }
    },
    //建立ws连接
    connectWs(uid) {
      this.$socket = new Socket({
        url: '/ws/game/' + 2 + '/' + uid,
        watchLogin: true,
        onmessage: msg => {
          if (msg.key == 'TO_BE_DISCONNECTED') {
            this.$store.commit('LOGIN_IS_SHOW', true);
            this.$message({
              offset: 50,
              message: '账号在别处登录',
              type: 'warning',
            });
          }
        },
      });
    },
    handleLogin() {
      this.playAudio();
      if (this.select == false) {
        this.$message({
          offset: 50,
          message: '请先勾选以下协议',
          type: 'warning',
        });
      } else if (this.select == true) {
        if (this.form.phoneNumber == '' && this.form.password == '') {
          this.$message({
            offset: 50,
            message: '请输入用户名或密码',
            type: 'warning',
          });
          return;
        }

        let data = {
          username: this.form.phoneNumber,
          password: this.form.password,
          equipment: '1',
        };
        const curBrowser = browser();
        if (!curBrowser.mobile) {
          // data.equipment='1'
        } else if (curBrowser.webapp) {
          data.equipment = '2';
        } else {
          data.equipment = '1';
        }
        getLogin(data)
          .then(res => {
            let code = res?.data?.code;
            if (code == 200) {
              setToken(res.data.token);
              this.$store.commit('LOGIN_IS_SHOW', false);
              this.$message({
                offset: 50,
                message: '登录成功',
                type: 'success',
              });
              //获取用户信息
              getUserInfo().then(res => {
                const { code, data } = res?.data || {};
                if (code != 200) return;
                this.connectWs(data.userId);
                this.$store.commit('USER_INFO', data);
                this.$router.replace(formPath || '/');
                this.$router.go(0);
              });
            } else {
              if (res.data.msg == '用户已封禁，请联系管理员') {
                this.$message({
                  offset: 50,
                  message: '账户异常，请联系管理员',
                  type: 'warning',
                });
              } else {
                this.$message({
                  offset: 50,
                  message: res.data.msg,
                  type: 'warning',
                });
              }
            }
          })
          .catch(err => {
            throw new Error(err.message);
          });
      }
    },
    handleClose() {
      this.playAudio();
      this.$router.go(-1);
    },
    handleAgree() {
      this.playAudio();
      this.select = !this.select;
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .fixed_bg;
  z-index: 999;
  background: rgba(0, 0, 0, 0.85);
  .close {
    top: 20px;
    right: 20px;
    .wh(20px, 20px);
    position: absolute;
    @media @max750 {
      .wh(18px, 18px);
    }
  }
  .content {
    .abs-ctl;
    .wh(835px, 629px);
    background: url(../../../assets/image/loginbg.png) no-repeat;
    background-size: 100% 100%;
    @media @max750 {
      .wh(100%, 100%);
      position: initial;
      background: none;
      transform: translate(0, 0);
    }
  }
  .logo {
    .wh(100%, 170px);
    position: relative;
    img {
      .abs-ctl;
      .wh(170px, auto);
      @media @max750 {
        .wh(90px, auto);
      }
    }
  }
  .main_top {
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    @media @max750 {
      font-size: 20px;
    }
  }
  .welcome {
    .sc(24px, @main);
    text-align: center;
    margin: 20px 0;
  }
  .main_center {
    width: 400px;
    height: 560px;
    padding: 30px 20px 20px;
    margin-left: auto;
    @media @max750 {
      width: 80%;
      padding: 0;
      margin: 0 auto;
    }
    .main_input {
      width: 100%;
      margin-top: 14px;
      position: relative;
      input {
        width: 100%;
        height: 46px;
        outline: none;
        padding: 0 22px;
        .sc(16px, #fff);
        background: #000;
        border-radius: 24px;
        border: 1px solid @bd2;
        box-sizing: border-box;
        &::placeholder {
          color: #606365;
        }
        &:focus {
          border-color: @main;
        }
        @media @max750 {
          height: 40px;
          padding: 0 15px;
          border-radius: 35px;
          border-color: #ffffff;
        }
      }
      .yzm {
        .abs-ct;
        right: 20px;
        color: @main;
        font-size: 14px;
        font-weight: 500;
        @media @max750 {
          color: @main;
        }
      }
    }
    .main_pwd {
      width: 100%;
      margin-top: 10px;
      text-align: right;
    }
    .main_input_right {
      .sc(14px, @main);
      text-align: right;
      margin: 10px 0;
      @media @max750 {
        .sc(13px, @main);
      }
    }
    .main_center_bottom {
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      .confirm {
        .wh(16px, 16px);
        cursor: pointer;
        margin-top: 3px;
        border-radius: 50%;
        border: 1px solid @bd2;
        @media @max750 {
          .wh(13px, 13px);
          margin-top: 3px;
          box-sizing: border-box;
          border-color: #727477;
        }
      }
      .confirmed {
        position: relative;
        border-color: @main;
        &::after {
          content: '';
          .abs-ctl;
          .wh(6px, 6px);
          border-radius: 50%;
          background: @main;
        }
        @media @max750 {
          border-color: @main;
          &::after {
            .wh(4px, 4px);
            background: @main;
          }
        }
      }
      .cont {
        flex: 1;
        line-height: 20px;
        padding-left: 10px;
        .sc(14px, #DDDDDD);
        @media @max750 {
          width: 95%;
          text-align: left;
          padding-left: 6px;
          .sc(12px, #fff);
        }
      }
    }
  }
  .main_bottom {
    .flex-j-center;
    margin-top: 20px;
    button {
      .wh(200px, 56px);
      .sc(20px, #fff);
      line-height: 56px;
      text-align: center;
      font-weight: bold;
      background: url(../../../assets/image/loginbtn.png) no-repeat;
      background-size: 100% 100%;
      @media @max750 {
        .wh(100%, 45px);
        font-size: 18px;
        line-height: 45px;
        .btn-bg;
      }
    }
  }
  .main_bottom_no {
    margin-top: 16px;
    text-align: center;
    @media @max750 {
      font-size: 13px;
    }
    span {
      color: @main;
      @media @max750 {
        color: @main;
      }
    }
  }
}
</style>
